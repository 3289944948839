import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum AccountVisibilityValue {
  edit = 'edit',
  view = 'view',
}

export interface AccountVisibility {
  visibility: AccountVisibilityValue,
}

@Injectable()
export class AccountVisibilityService {
  visibilitySubject = new BehaviorSubject<AccountVisibility | undefined>(undefined);
  visibility$ = this.visibilitySubject.asObservable();

  set(visibility: AccountVisibility | undefined): void {
    this.visibilitySubject.next(visibility);
  }
}
